import swal from 'sweetalert2'

class DeleteConfirmation {
  constructor () {
    this.deleteButtons = document.querySelectorAll('a.js-delete-confirmation[data-method]')
    this.translation = document.querySelector('div.js-swal-delete-confirmation')
  }

  initClickListener () {
    const that = this

    for (let i = 0; i < that.deleteButtons.length; i++) {
      const button = that.deleteButtons[i]

      button.addEventListener('click', (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        swal.fire({
          title: that.translation.dataset.swalTitle,
          text: that.translation.dataset.swalSub,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: that.translation.dataset.swalBSubmit,
          cancelButtonText: that.translation.dataset.swalBCancel
        }).then((result) => {
          if (result.isConfirmed) {
            that.constructor.delete(button)
          }
        })
      })
    }
  }

  static delete (button) {
    const url = button.getAttribute('href')
    const form = document.createElement('form')
    form.setAttribute('action', url)
    form.setAttribute('method', 'POST')

    const method = button.dataset.method
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', '_method')
    input.setAttribute('value', method)

    form.append(input)
    document.querySelector('body').append(form)

    form.submit()
  }

  init () {
    const that = this
    that.initClickListener()
  }
}

const deleteConfirmation = new DeleteConfirmation()
deleteConfirmation.init()
